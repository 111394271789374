<template>
  <v-dialog
    v-model="closeDialog"
    persistent
    scrollable
    max-width="500"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="my-1"
        fab
        x-small
        color="primary"
        outlined
        v-bind="attrs"
        :title="$t('taskRequests.dialogClose.title')"
        v-on="on"
        @click="closeDialogInit"
      >
        <v-icon>
          mdi-check
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        {{ $t('taskRequests.dialogClose.title') }}
      </v-card-title>

      <Loading :loading="!loaded" />

      <v-card-text>
        <v-container
          v-if="loaded && closeModel"
          fluid
        >
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="closeModel.data.closedOn"
                use-iso
                :label="$t('taskRequests.dialogClose.closingTime')"
                date-format="yyyy-MM-dd"
                time-format="HH:mm:ss"
                :time-picker-props="timeProps"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template
                  slot="actions"
                  slot-scope="{ parent }"
                >
                  <v-btn
                    outlined
                    color="primary"
                    @click.native="parent.cancelHandler"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    @click="parent.okHandler"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </template>
              </v-datetime-picker>              
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="closeModel.data.closingReason"
                :items="closeModel.metadata.closings"
                item-text="text"
                item-value="value"
                :label="$t('taskRequests.dialogClose.closingReason')"
                outlined
                dense
                required
                @change="hasChanged = true"
              />
              <v-alert
                v-if="!closeModel.data.closingReason"
                outlined
                type="warning"
                border="top"
              >
                {{ $t('taskRequests.dialogClose.closingReasonWarning') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="closeDialogCancel"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          :disabled="!hasChanged"
          @click="closeDialogSave"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'TaskRequestDialogClose',
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      closeModel: Object,
      closeDialog: false,
      loaded: false,
      saving: false,
      hasChanged: false,
      timeProps: {
        format:"24hr",
        useSeconds: true,
      }
    }
  },
  methods: {
    closeDialogInit: function () {
      var vm = this;
      vm.closeDialog = true;

      vm.$AuthService.api
      .get(`taskrequest/close/${this.$route.params.id}`)
      .then((response) => {
        response.data.metadata.closings.forEach(x => x.text = vm.$t(`taskRequests.closingReasons.${x.text}`));
        vm.closeModel = response.data;
        vm.loaded = true;
      });
    },
    closeDialogSave: function () {
      var vm = this;
      vm.saving = true;
      //todo: proper validation
      if (!this.closeModel.data.closingReason) {
        //this is required
        return;
      }

      vm.$AuthService.api
        .put(`taskrequest/close/${this.$route.params.id}`, this.closeModel.data)
        .then((response) => {
          if (response.data.success) {
            vm.saving = false;
            vm.hasChanged = false;
            vm.closeDialog = false;
            this.$emit("saved");
          } else {
            //TODO: Error handle...
        }
      });
    },
    closeDialogCancel: function () {
      this.closeModel = null;
      this.closeDialog = false;
      this.hasChanged = false;
      this.loaded = false;
    },
  }
}
</script>
