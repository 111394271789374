<template>
  <v-container fluid>
    <Loading :loading="loading" />

    <div v-if="model">
      <v-row>
        <v-col>
          <TaskRequestCard
            :item="model.data"
            :metadata="model.metadata"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="2">
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('taskRequests.details.information.title') }}
              </v-toolbar-title>

              <v-spacer />

              <TaskRequestEditDialog @saved="init" />
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-simple-table>
                    <template #default>
                      <tbody>
                        <tr>
                          <th>Lokation</th>
                          <td><LocationLink :id="model.data.locationId" /></td>
                        </tr>                        <tr>
                          <th>Sagsnr.</th>
                          <td>{{ model.data.code }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col>
                  <v-card
                    :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
                    elevation="0"
                  >
                    <v-card-subtitle>
                      Beskrivelse
                    </v-card-subtitle>
                    <v-card-text>
                      {{ model.data.descriptionAsHtml }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Oprettet</th>
                        <td>{{ model.data.createdOn | formatDate }}</td>
                        <td />
                      </tr>
                      <tr>
                        <th>Oprettet af</th>
                        <td>{{ model.data.createdBy }}</td>
                        <td />
                      </tr>
                      <tr>
                        <th>Behovsdato</th>
                        <td>{{ model.data.requiredOn | formatDate }}</td>
                        <td />
                      </tr>
                      <tr v-if="model.data.sourceSupportTicketId">
                        <th>Kilde</th>
                        <td>
                          <router-link :to="`/supportticket/${model.data.sourceSupportTicketId}`">
                            {{ model.data.sourceSupportTicketCodeAndTitle }}
                          </router-link>
                        </td>
                        <td />
                      </tr>
                      <tr v-if="model.data.closedOn">
                        <th v-if="model.data.closedOn">
                          Lukket
                        </th>
                        <td>{{ model.data.closedOn | formatDate }}<span v-if="model.data.closingReason"> ({{ $t(`taskRequests.closingReasons.${model.data.closingReason}`) }})</span></td>
                        <td />
                      </tr>
                      <tr v-else>
                        <th>Luk</th>
                        <td>
                          <TaskRequestDialogClose @saved="init" />
                        </td>
                      </tr>
                      <tr v-if="model.data.createdDeliveryId">
                        <th>Leverance</th>
                        <td>
                          <router-link :to="`/delivery/${model.data.createdDeliveryId}`">
                            {{ model.data.createdDeliveryCodeAndTitle }}
                          </router-link>
                        </td>
                      </tr>
                      <tr v-else>
                        <th>Leverance</th>
                        <td v-if="!model.data.closedOn">
                          <DeliveryCreate
                            :list-button="false"
                            :task-request="model.data"
                            @saved="init"
                          />
                        </td>
                        <td v-else>
                          <router-link :to="`/delivery/${model.data.createdDeliveryId}`">
                            {{ model.data.createdDeliveryCodeAndTitle }}
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TaskRequestCard from '@/components/task-request/TaskRequestCard';
import TaskRequestDialogClose from '@/components/task-request/TaskRequestDialogClose'
import TaskRequestEditDialog from '@/components/task-request/TaskRequestEditDialog'
import DeliveryCreate from '@/components/dashboard/DeliveryCreate'
import LocationLink from '@/components/LocationLink'
import Loading from '@/components/Loading'
  
export default {  
  name: 'TaskRequestDetail',
  components: {
    TaskRequestCard,
    TaskRequestDialogClose,
    TaskRequestEditDialog,
    DeliveryCreate,
    LocationLink,
    Loading
  },
  data() {
    return {
      model: null,
      loading: false
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`taskrequest/detail/${this.$route.params.id}`);
        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>