<template>
  <v-dialog
    v-model="dialog"
    scrollable
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        fab
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Ændring af opgavebeskrivelse
      </v-card-title>

      <Loading :loading="loading" />

      <v-card-text>
        <v-container
          v-if="model"
          fluid
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="model.data.location"
                :items="model.metadata.locations"
                item-text="text"
                item-value="value"
                label="Lokation"
                return-object
                :search-input.sync="locationInput"
                auto-select-first
                small-chips
                outlined
                dense
                @input="locationInput = null"
              />
              <v-autocomplete
                v-model="model.data.departments"
                :items="model.metadata.departments"
                item-text="text"
                item-value="value"
                label="Enheder"
                return-object
                :search-input.sync="departmentInput"
                auto-select-first
                small-chips
                outlined
                dense
                multiple
                @input="departmentInput = null"
              />
              <v-autocomplete
                v-model="model.data.subjectAreas"
                :items="model.metadata.subjectAreas"
                item-text="text"
                item-value="value"
                label="Tekniske områder"
                return-object
                :search-input.sync="subjectInput"
                auto-select-first
                small-chips
                outlined
                dense
                multiple
                @input="subjectInput = null"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuRequiredOn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="model.data.requiredOn"
                    label="Behovsdato"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="model.data.requiredOn"
                  @input="menuRequiredOn = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="model.data.title"
                label="Titel"
              />
              <v-currency-field
                v-model="model.data.expectedConsultants"
                label="Forv. antal konsulenter"
              />
              <v-currency-field
                v-model="model.data.expectedDays"
                label="Forv. teknikerdage"
              />
              <v-currency-field
                v-model="model.data.expectedPrice"
                label="Forv. DKK"
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="model.data.description"
                label="Beskrivelse..."
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="dialog = false"
        >
          Annuller
        </v-btn>
        <v-btn
          color="secondary"
          :loading="saving"
          @click="save"
        >
          Gem
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'TaskRequestEditDialog',
  components: {
    Loading
  },
  data() {
    return {
      dialog: false,
      model: null,
      loading: false,
      saving: false,
      locationInput: null,
      departmentInput: null,
      subjectInput: null,
      menuRequiredOn: false
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`taskrequest/edit/${this.$route.params.id}`);
        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.saving = true;

      try {
        await this.$AuthService.api.put(`taskrequest/edit/${this.$route.params.id}`, this.model.data);

        this.dialog = false;
        this.$emit('saved');
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>