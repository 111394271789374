<template>
  <CaseCard
    color="blue lighten-3"
    icon="mdi-format-list-checks"
    :item="item"
    :to="to"
    :departments="metadata.departments"
    :subject-areas="metadata.subjectAreas"
    :active-departments="item.departments.map((d) => d.value)"
    :active-subject-areas="item.subjectAreas.map((s) => s.value)"
    :hide-status="hideStatus"
  />
</template>

<script>
import CaseCard from '@/components/CaseCard'

export default {
  name: 'TaskRequestCard',
  components: {
    CaseCard
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    to: String,
    hideStatus: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
